<template>
  <main v-if="data">
    <div class="banner">
      <div class="swiper-slide">
        <router-link
          :to="item.slideMLink"
          v-for="(item, index) in data.listimg"
          :key="index"
          :style="{ backgroundImage: `url(${item.slideImg})` }"
          :title="item.slideAlt"
          class="swiper-content"
        >
        </router-link>
      </div>
    </div>

    <div class="main-title">
      如何联系BNCC <br />
      <span> How to contact the divsion </span>
    </div>

    <div class="free-tel-model"></div>
    <div class="free-tel-wapper">
      <div class="free-tel-title">免费电话</div>
      <div class="free-tel-tip">
        <div>请告知您的电话号码，我们将立即回电</div>
        <span>通话对您免费，请放心接听</span>
      </div>
      <input
        type="tel"
        id="callMeTel"
        name="callMeTel"
        class="About_tellPhone_input"
        placeholder="请输入电话号码"
        value=""
      />
      <div class="free-tel-btn">
        <span class="free-tel-cancel">取消</span>
        <span class="free-tel-confirm">立即回电</span>
      </div>
    </div>
    <div class="position-info">
      <p>业务咨询：010-58103778</p>
      <!--      <p>仓库：010-84840368</p>-->
      <p>企业 QQ : 4000677678</p>
      <p>Email :  info@bncc.com</p>
<!--      <p>地址：北京市朝阳区建国路15号院</p>-->
<!--      <p>邮编：100024</p>-->
      <div class="contract-wapper">
        <a
                href="https://tb.53kf.com/code/client/099be4cda651b04a92a215ad1e6edd8d8/1"
          class="inline"
        >
          <img src="https://beinabncc.oss-cn-beijing.aliyuncs.com/News/b0738a22523a6457a9db0a8efcc2c679.png" alt="" />
          在线客服
        </a>
        <!--        <a-->
        <!--          class="free-tel"-->
        <!--          @click="contractUs"-->
        <!--          href="javascript:;"-->
        <!--          rel="nofollow"-->
        <!--        >-->
        <!--          <img src="http://www.bncc.org.cn/static/m/images/icon99.png" alt="" />-->
        <!--          免费电话-->
        <!--        </a>-->
      </div>
    </div>
    <div class="block">
      <div class="block-title">
        公司简介<br />
        <p>COMPANY PROFILE</p>
      </div>
      <div class="video-wapper">
        <p class="video_title">北纳生物——河南省工业微生物菌种工程技术研究中心</p>
        <video
          id="player-container-id"
          v-if="videoFileid"
          preload="auto"
          style="width: 100%; height: 100%"
          playsinline
          webkit-playinline
          x5-playinline
        ></video>
      </div>
      <div class="video-wapper" style="margin: 1rem 0;">
        <p class="video_title">北纳生物综合实验大楼新面貌</p>
        <video
                id="player-weiye-id"
                v-if="videoFileid"
                preload="auto"
                style="width: 100%; height: 100%"
                playsinline
                webkit-playinline
                x5-playinline
        ></video>
      </div>

      <div class="video-wapper" style="margin: 1rem 0;">
        <p class="video_title">天佑北纳MV</p>
        <video
                id="player-bncc-id"
                v-if="videoFileid"
                preload="auto"
                style="width: 100%; height: 100%"
                playsinline
                webkit-playinline
                x5-playinline
        ></video>
      </div>
    </div>
    <div class="block">
      <div class="block-title border-bottom-1px">
        招商直线电话<br />
        <p>How to contact the divsion</p>
      </div>
      <div class="custom-service">
        <div class="swiper-container" id="custom-swiper">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide"
              v-for="(item, index) in data.kefulist"
              :key="index"
            >
              <ul class="custom-service-ul">
                <li v-for="(itm, i) in item" :key="i">
                  <router-link
                    :to="{ name: 'Personal', query: { uid: itm.serverUserId } }"
                  >
                    <img class="avatar" :src="itm.serverImg" alt="" />
                    <div class="service-info">
                      <span>
                        <a href="javascript:;" style="color: rgb(61, 194, 214)">
                          {{ itm.serverName }}
                        </a>
                      </span>
                      <span>手机：{{ itm.serverMobile }}</span>
                      <span>QQ：{{ itm.serverQq }}</span>
                    </div>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>

          <div class="swiper-pagination"></div>
        </div>
      </div>
    </div>
    <div class="block">
      <div class="block-title border-bottom-1px">
        账户信息<br />
        <p>account information</p>
      </div>
      <div class="account-info">
        <ul>
<!--          <li>-->
<!--            <p style="padding: 0">① 账户一（增值税专用发票+普通税票 ）</p>-->
<!--            <p>账户名称：北京北纳创联生物技术研究院</p>-->
<!--            <p>账号：1100 1119 9000 5250 2558</p>-->
<!--            <p>开户行：中国建设银行北京环贸支行</p>-->
<!--          </li>-->
          <li>
            <p style="padding: 0">①  账户一（普通税票）</p>
            <p>账户名称：商城北纳创联生物科技有限公司</p>
            <p>账号：1105 173 7200 0000 0221</p>
            <p>开户行：中国建设银行股份有限公司北京环贸支行</p>
          </li>
          <li>
            <p style="padding: 0">② 个人银行卡账户（个人账户）</p>
            <p>农业银行：6228 4800 1900 0311 175 肖越洋</p>
            <p>工商银行：6212 2602 0018 7135 526 肖越洋</p>
          </li>
        </ul>
      </div>
    </div>
    <div class="tip-block">
      <img
              class="create-img"
              src="https://beinabncc.oss-cn-beijing.aliyuncs.com/zhengshu//bn-java-about-bj20201125.jpg"
              alt=""
      />
      <div class="tip">
        <span>行业翘楚 有口皆碑</span>
        <p>专注于细胞、微生物菌种领域，集研发、生产和销售为一体的生物高科技企业。</p>
      </div>
    </div>
    <div class="development-history">
      <div class="dev-title">十年磨一剑</div>
      <div class="dev-desc">扎根生物 心无旁骛 风雨十载 砥砺前行</div>
      <div class="introduction">
        <div style="width:80%" class="swiper-container" id="history-swiper">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <p class="about_introduction_title">2010年8月</p>
              <p class="about_introduction_text">北纳生物成立，是一家专注于细胞、微生物菌种领域，集研发、生产和销售为一体的生物高科技企业。</p>
            </div>
            <div class="swiper-slide">
              <p class="about_introduction_title">2012年11月</p>
              <p class="about_introduction_text"> 伟业计量经过多年来技术积累，于2012年正式成立北京北方伟业计量技术研究院，专业致力于国家标准物质的研制工作。</p>
            </div>
            <div class="swiper-slide">
              <p class="about_introduction_title">2015年8月</p>
              <p class="about_introduction_text">北纳生物（BNCC）昆山实验室成立，实现了由销售平台到生物研发企业的完美转型。</p>
            </div>
            <div class="swiper-slide">
              <p class="about_introduction_title">2017年8月</p>
              <p class="about_introduction_text"> 申报的第一批国家标准物质获得国家质检总局证书批复。伟业计量标物的成功申报，标志着伟业计量的战略发展进入一个新的里程。</p>
            </div>
            <div class="swiper-slide">
              <p class="about_introduction_title">2017年10月</p>
              <p class="about_introduction_text"> 申报成功16种农残类国家二级标准物质。</p>
            </div>
            <div class="swiper-slide">
              <p class="about_introduction_title">2017年12月</p>
              <p class="about_introduction_text"> 伟业计量申报成功18种农残类、16种无机类国家二级标准物质。</p>
            </div>
            <div class="swiper-slide">
              <p class="about_introduction_title">2018年5月</p>
              <p class="about_introduction_text"> 北纳生物申请新型专利十余项，先进的设备，专业的人才，为您提供更好的技术支持。</p>
            </div>
            <div class="swiper-slide">
              <p class="about_introduction_title">2018年10月</p>
              <p class="about_introduction_text"> 北纳生物应邀参加慕尼黑上海分析生化展，与业内专家学者交流洽谈。</p>
            </div>

            <div class="swiper-slide">
              <p class="about_introduction_title">2018年4月</p>
              <p class="about_introduction_text"> 申报成功14种农残类、9种无机类国家二级标准物质。</p>
            </div>
            <div class="swiper-slide">
              <p class="about_introduction_title">2018年7月</p>
              <p class="about_introduction_text"> 2018.07，申报成功24种农残类、31种无机类国家二级标准物质。</p>
            </div><div class="swiper-slide">
            <p class="about_introduction_title">2018年11月</p>
            <p class="about_introduction_text"> 伟业计量举行乔迁盛典，北京专家组和当地政府受邀出席仪式。</p>
          </div>

            <div class="swiper-slide">
              <p class="about_introduction_title">2018年11月</p>
              <p class="about_introduction_text"> 北纳生物入驻轻工业园区，实验室扩建项目正式完成，大大提升细胞、菌株研发生产的技术标准和操作环境。</p>
            </div>
            <div class="swiper-slide">
              <p class="about_introduction_title">2018年12月</p>
              <p class="about_introduction_text"> 伟业计量成功申报77种国家二级标准物质。</p>
            </div>
            <div class="swiper-slide">
              <p class="about_introduction_title">2019年1月</p>
              <p class="about_introduction_text"> 北纳生物通过ISO9001质量体系认证，获取认证证书。</p>
            </div>

            <div class="swiper-slide">
              <p class="about_introduction_title">2019年4月</p>
              <p class="about_introduction_text"> 北纳生物科研基地开工奠基，在不远的将来国内一流的菌株和细胞实验室，分子生物实验室、培养基生产线、微生物检测中心、电子商务中心即将建成。</p>
            </div>
            <div class="swiper-slide">
              <p class="about_introduction_title">2019年6月</p>
              <p class="about_introduction_text"> 北纳生物应邀参加CPHI China2019，开拓国际化市场新格局。</p>
            </div>
            <div class="swiper-slide">
              <p class="about_introduction_title">2019年7月</p>
              <p class="about_introduction_text"> 北纳生物定量菌株研发与孵化工程顺利完成。</p>
            </div>
            <div class="swiper-slide">
              <p class="about_introduction_title">2019年7月</p>
              <p class="about_introduction_text"> 伟业计量成功申报10种国家二级标准物质。</p>
            </div>
            <div class="swiper-slide">
              <p class="about_introduction_title">2019年11月</p>
              <p class="about_introduction_text"> 北纳生物成功研发DH5α感受态细胞。</p>
            </div>
            <div class="swiper-slide">
              <p class="about_introduction_title">2019年12月</p>
              <p class="about_introduction_text"> 伟业计量再次成功申报81种国家二级标准物质！</p>
            </div>
            <div class="swiper-slide">
              <p class="about_introduction_title">2020年3月</p>
              <p class="about_introduction_text"> 伟业计量旗下豫南检测中心历经10月奋战，终顺利取得CMA认证。</p>
            </div>
            <div class="swiper-slide">
              <p class="about_introduction_title">2020年4月</p>
              <p class="about_introduction_text"> 北纳生物成功通过审核加入中国微生物学会，成为正式会员。</p>
            </div>
            <div class="swiper-slide">
              <p class="about_introduction_title">2020年6月</p>
              <p class="about_introduction_text"> 伟业计量旗下豫南检测中心顺利取得CATL证书。</p>
            </div>
            <div class="swiper-slide">
              <p class="about_introduction_title">2020年10月</p>
              <p class="about_introduction_text"> 伟业计量和豫南检测中心联合申报成功，获批77种国家二级标准物质！</p>
            </div>
            <div class="swiper-slide">
              <p class="about_introduction_title">2020年12月</p>
              <p class="about_introduction_text"> 河南省工业微生物菌种工程技术研究中心成功落户北纳生物。</p>
            </div>
            <div class="swiper-slide">
              <p class="about_introduction_title">2021年6月</p>
              <p class="about_introduction_text"> 北纳生物成功研发新品芽孢悬液，配备COA溯源证书及鉴定报告。</p>
            </div>
            <div class="swiper-slide">
              <p class="about_introduction_title">2021年7月</p>
              <p class="about_introduction_text"> 北纳生物成功研发食品中菌落总数标准物质。</p>
            </div>
            <div class="swiper-slide">
              <p class="about_introduction_title">2021年7月</p>
              <p class="about_introduction_text"> 水质粪大肠菌群测定标准质控样品、阳性质控样品及阴性质控样品隆重上市，可用于地表水、地下水、生活污水和工业废水中等粪大肠菌群的测定。</p>
            </div>
            <div class="swiper-slide">
              <p class="about_introduction_title">2021年7月</p>
              <p class="about_introduction_text"> 伟业计量成功申报28种国家一级标准物质！此次生物基体类元素分析标准物质的成功申报，标志着伟业研发水平跻身国内先进行列！</p>
            </div>
            <div class="swiper-slide">
              <p class="about_introduction_title">2021年8月</p>
              <p class="about_introduction_text"> BNCC再出新品-粪大肠菌群测定标准质控样品，满足客户在标准应用过程中的方法验证、质量控制、人员考核等方面需求。</p>
            </div>
            <div class="swiper-slide">
              <p class="about_introduction_title">2021年11月</p>
              <p class="about_introduction_text"> 北纳生物科研基地正式竣工投产，建筑面积10000平，实验区4000平，其中P2实验室2000平，此次竣工投产标志着北纳已迈入全新的发展阶段。</p>
            </div>
            <div class="swiper-slide">
              <p class="about_introduction_title">2021年12月</p>
              <p class="about_introduction_text"> 伟业计量成功研制并终审通过了34种生物元素分析标准物质。</p>
            </div>
            <div class="swiper-slide">
              <p class="about_introduction_title">2021年12月</p>
              <p class="about_introduction_text">伟业计量成功申报18个国家二级标准物质。</p>
            </div>
            <div class="swiper-slide">
              <p class="about_introduction_title">2021年12月</p>
              <p class="about_introduction_text"> BNCC推出自主研发水质检测系列质控产品。</p>
            </div>
            <div class="swiper-slide">
              <p class="about_introduction_title">2021年12月</p>
              <p class="about_introduction_text"> BNCC推出自主研发新型冠状病毒N基因假病毒核酸质控品。</p>
            </div><div class="swiper-slide">
            <p class="about_introduction_title">2022年1月</p>
            <p class="about_introduction_text"> BNCC推出自主研发大肠菌群计数质控样品。</p>
          </div>
            <div class="swiper-slide">
              <p class="about_introduction_title">2022年1月</p>
              <p class="about_introduction_text"> 伟业计量再次获批14个国家二级标准物质。</p>
            </div>
            <div class="swiper-slide">
              <p class="about_introduction_title">2022年2月</p>
              <p class="about_introduction_text"> BNCC推出大肠杆菌、金黄色葡萄球菌等菌类核酸参考品。</p>
            </div>
            <div class="swiper-slide">
              <p class="about_introduction_title">2022年3月</p>
              <p class="about_introduction_text"> BNCC成功研制CHO细胞DNA含量测定质控品。</p>
            </div>
            <div class="swiper-slide">
              <p class="about_introduction_title">2022年4月</p>
              <p class="about_introduction_text"> BNCC成功研制菌类核酸检测试剂盒、鸡源性核酸检测试剂盒。</p>
            </div>
            <div class="swiper-slide">
                <p class="about_introduction_title">2023年6月</p>
                <p class="about_introduction_text">商标在香港成功注册</p>
            </div>
            <div class="swiper-slide">
                <p class="about_introduction_title">2023年8月</p>
                <p class="about_introduction_text">BNCC研发大肠埃希氏菌平板计数标准物质、菌落总数平板计数标准物质、人4型腺病毒基因组DNA标准物质分别包含高中低三个浓度成功获批9个国家二级标准物质</p>
            </div>
            <div class="swiper-slide">
                <p class="about_introduction_title">2023年10月</p>
                <p class="about_introduction_text">在欧盟及英国申请注册的“BNCC”商标，获得欧盟及英国知识产权局分别核准注册成功</p>
            </div>
            <div class="swiper-slide">
                <p class="about_introduction_title">2024年2月</p>
                <p class="about_introduction_text">北纳生物（BNCC）成功获得中国合格评定国家认可委员会颁发的验室认可证书（注册号：CNAS L20578）</p>
            </div>
            <div class="swiper-slide">
                <p class="about_introduction_title">2024年5月</p>
                <p class="about_introduction_text">北纳生物（BNCC）经河南省发展和改革委员会批准，成功获批省级微生物种质资源保藏工程研究中心</p>
            </div>
            <div class="swiper-slide">
                <p class="about_introduction_title">2024年6月</p>
                <p class="about_introduction_text">承担定量菌种保护剂的优选及其标准物质的研制与产业化的省级重点研发专项</p>
            </div>
          </div>
        </div>
        <div class="ly_home_product_left swiper-button-prev"></div>
        <div class="ly_home_product_right swiper-button-next"></div>
        <!--
                <div class="ly_home_product_left swiper-button-prev" @click="toPrev()" style="z-index: 999"></div>
                <div class="ly_home_product_right swiper-button-next" @click="toNext()"></div>
        -->
      </div>
    </div>
  </main>
  <loading :options="loadOption" v-else></loading>
</template>

<script lang="ts">
import {
  defineComponent,
  ref,
  inject,
  nextTick,
  watch,
  onUnmounted,
} from "vue";
import axios from "@/api/axios";
import Toast from "@/components/UI/Toast";
import Loading from "@/components/UI/Loading";
import Swiper from "swiper";
import asyncLoadJs from "@/hooks/useAsyncLoadJs";
// asyncLoadJs(
//   "//imgcache.qq.com/open/qcloud/video/tcplayer/lib/hls.min.0.8.8.js",
//   "about"
// ).then(() => {
//   asyncLoadJs(
//     "//imgcache.qq.com/open/qcloud/video/tcplayer/tcplayer.min.js",
//     "about"
//   );
// });
import vTCPlayer from "@/hooks/useTcplayer";
import Modal from "@/components/UI/Modal";
export default defineComponent({
  name: "About",
  components: {
    Loading,
  },
  setup() {
    const data = ref(null);

    nextTick(() => {
      asyncLoadJs(
        "//imgcache.qq.com/open/qcloud/video/tcplayer/lib/hls.min.0.8.8.js",
        "about"
      ).then(() => {
        asyncLoadJs(
          "//imgcache.qq.com/open/qcloud/video/tcplayer/tcplayer.min.js",
          "about"
        ).then((res) => {
          // vTCPlayer("player-container-id", "3270835015415163965");
          vTCPlayer("player-container-id", "1397757890127890262");
          vTCPlayer("player-weiye-id", "387702291911916995");
          // vTCPlayer("player-weiye-id", "3270835015415163965");
          vTCPlayer("player-bncc-id", "387702292805539834");
        });
      });
    })

    const userInfo = inject("userInfo") as any;
    function contractUs() {
      // 用户登录，自动提交用户的账号默认的手机号
      if (userInfo?.mobile) {
        Toast({
          type: "success",
          title: "请求已提交，电话拨打中，请稍后...",
        });
        axios.post("/M/Server/GetRandKefuName").then((res) => {
          const kefuname = res.data.msg;
          const urlstr =
            "http://211.151.35.102/app?Action=Dialout&ActionID=1234567890&Account=N000000014105&PBX=1.1.1.108&Exten=" +
            userInfo.mobile +
            "&FromExten=" +
            kefuname;

          window.open(urlstr);
        });
      } else {
        Modal({
          title: "免费电话",
          content: `  <div>请告知您的电话号码，我们将立即回电</div>
                <span>通话对您免费，请放心接听</span>`,
          dangerouslyUseHTMLString: true,
          placeholder: "请输入电话号码",
          input: true,
          inputPattern: /^1[3|4|5|6|7|8|9][0-9]\d{4,8}$/,
          inputErrorMessage: "请输入正确的手机号或带区号的固定电话",
          onConfirm: (tel) => {
            console.log(tel);

            Toast({
              type: "success",
              title: "请求已提交，电话拨打中，请稍后...",
            });

            axios.post("/M/Server/GetRandKefuName").then((res) => {
              const kefuname = res.data.msg;
              const urlstr =
                "http://211.151.35.102/app?Action=Dialout&ActionID=1234567890&Account=N000000014105&PBX=1.1.1.108&Exten=" +
                tel +
                "&FromExten=" +
                kefuname;

              window.open(urlstr);
            });
          },
        });
      }
    }
    let Videoplayer: any = null;
    let VideoplayerContent: any = null;
    const videoFileid = ref(true);
    axios
      .get("/M/Home/About")
      .then((res) => {
        if (res.data.success) {
          const kefulist = [];
          const length = Math.ceil(res.data.obj.kefulist.length / 6);
          for (let i = 0; i < length; i++) {
            kefulist.push(res.data.obj.kefulist.slice(i * 6, i * 6 + 6));
          }
          data.value = { ...res.data.obj, kefulist };
          nextTick(() => {
            Videoplayer = vTCPlayer(
              "player-container-id",
              "1397757890127890262"
            );
            VideoplayerContent = vTCPlayer(
              "player-weiye-id",
              "387702291911916995"
            );
            VideoplayerContent = vTCPlayer(
                    "player-bncc-id",
                    "387702292805539834"
            );
          });
        } else {
          Toast({
            type: "error",
            title: res.data.msg,
          });
        }
      })
      .catch((err) => {
        console.log("err");
      });

    watch(data, () => {
      nextTick(() => {
        const swiper = new Swiper("#custom-swiper", {
          initialSlide: 0,
          autoplay: true,
          loop: true,
          observer: true, //修改swiper自己或子元素时，自动初始化swiper
          observeParents: true, //修改swiper的父元素时，自动初始化swiper
          on: {
            observerUpdate: function () {
              this.slideTo(0);
            },
          },
          pagination: {
            el: ".swiper-pagination",
          },
        });
        const swiper2 = new Swiper("#history-swiper", {
          initialSlide: 0, //设定初始化时slide的索引
          autoplay: true, //设置为true启动自动切换
          loop: true, //开启循环模式
          observer: true, //修改swiper自己或子元素时，自动初始化swiper
          observeParents: true, //修改swiper的父元素时，自动初始化swiper
          navigation:{
            nextEl:".swiper-button-next",
            prevEl:".swiper-button-prev",
          },
          on: {
            observerUpdate: function () {
              this.slideTo(0);
            },
          }
        });
      });
    });

    onUnmounted(() => {
      Videoplayer.dispose();
      VideoplayerContent.dispose();
      videoFileid.value = false;
    });

    const loadOption = {
      text: "加载中...",
      color: "#df0024",
      textColor: "#df0024",
    };
    return {
      data,
      loadOption,
      contractUs,
      videoFileid,
    };
  },
});
</script>


<style lang="scss" scoped>
.banner {
  margin-bottom: 0.533rem;
}



.banner .swiper-content {
  width: 100%;
  height: 4rem;
  display: block;
  position: relative;
  overflow: hidden;
  background-size: cover;
  background-position: center center;
}

  .banner .swiper-content img {
    /*width: 100%;*/
    height: 4rem;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    display: block;
  }

  .banner .swiper-container {
    --swiper-theme-color: #fff;
    /* 设置Swiper风格 */
    --swiper-navigation-color: #fff;
    /* 单独设置按钮颜色 */
    --swiper-navigation-size: 1.067rem;
    /* 设置按钮大小 */
    --swiper-pagination-color: #df0024;
    /* 两种都可以 */
  }

  .banner .my-bullet {
    display: inline-block;
    width: 0.347rem;
    height: 0.067rem;
    background-color: #ffffff;
    margin: 0 0.067rem;
    border-radius: 0;
    opacity: 1;
  }

  .banner .my-bullet-active {
    background-color: #df0024;
  }

  .free-tel-model {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 10000;
    display: none;
  }

  .free-tel-wapper {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 90%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    line-height: 1.5;
    background-color: rgba(255, 255, 255, 0.95);
    border-radius: 0.267rem;
    z-index: 10001;
    display: none;
  }
  .free-tel-title {
    padding: 0.533rem 0.533rem 0.267rem;
    margin-bottom: -0.667rem;
    text-align: center;
    font-size: 0.467rem;
    font-weight: 400;
    color: #333;
  }

  #callMeTel {
    margin-left: 0.533rem;
    width: 87%;
    height: 1.067rem;
    line-height: 1.067rem;
    border: 0.027rem solid #c10728;
    padding-left: 0.267rem;
    outline: none;
  }

  .free-tel-btn {
    border-top: 0.027rem solid #eee;
    font-size: 0;
    margin-top: 0.533rem;
  }
  .free-tel-btn span {
    display: inline-block;
    height: 1.173rem;
    line-height: 1.173rem;
    color: #007aff;
    font-size: 0.453rem;
    font-weight: 400;
    cursor: pointer;
    text-decoration: none;
    background-color: transparent;
    width: 50%;
    text-align: center;
    zoom: 1;
    box-sizing: border-box;
  }

  .free-tel-btn .free-tel-cancel {
    color: #999;
  }
  .free-tel-confirm {
    border-left: 0.027rem solid #eee;
  }

  .free-tel-tip {
    padding: 0.533rem 0.533rem 0.267rem;
    font-size: 0.28rem;
  }

  main {
    padding-bottom: 1.333rem;
  }

  .main-title {
    font-size: 0.46rem;
    color: #444444;
    font-weight: bold;
    width: 9.467rem;
    margin: 0 auto;
    border-bottom: 0.027rem solid #eee;
    padding-bottom: 0.1rem;
  }

  .main-title span {
    text-transform: uppercase;
    font-size: 10px;
    color: #666;
    font-weight: normal;
    position: relative;
    top: -5px;
  }

  .position-info {
    font-size: 0.4rem;
    color: #666;
    line-height: 0.64rem;
    padding: 0.4rem 0.267rem;
    width: 9.467rem;
    box-sizing: border-box;
    margin: 0 auto;
    padding-left: 0;
  }
  .position-info p {
    margin: 0;
  }
  .contract-wapper {
    margin-top: 0.533rem;
    padding-left: 0.267rem;
  }
  .contract-wapper a {
    display: inline-block;
    width: 3.733rem;
    height: 1.067rem;
    line-height: 1.067rem;
    text-align: left;
    font-size: 0.427rem;
    border: 0.027rem solid #e5e5e5;
    border-radius: 0.133rem;
    padding-left: 0.333rem;
    box-sizing: border-box;
    color: #666;
  }
  .contract-wapper .inline {
    margin-right: 0.8rem;
  }

  .contract-wapper img {
    width: 0.467rem;
    height: 0.48rem;
    vertical-align: middle;
    margin-right: 0.333rem;
  }

  .block {
    border-top: 0.16rem solid #eee;
    padding: 0 0.267rem;
    padding-top: 0.4rem;
    padding-bottom: 0.667rem;
  }

  .block-title {
    font-size: 0.46rem;
    color: #444444;
    font-weight: bold;
    padding-bottom: 10px;
  }

  .block-title p {
    font-size: 10px;
    color: #666;
    font-weight: normal;
    text-transform: uppercase;
    margin: 0;
    position: relative;
    top: 2px;
  }

  .border-bottom-1px {
    border-bottom: 0.027rem solid #eee;
  }

  .custom-service {
    width: 9.467rem;
    margin: 0 auto;
    padding: 0.533rem 0;
  }
  .custom-service-ul {
    padding-bottom: 2.133rem;
    overflow: hidden;
  }
  .custom-service-ul li {
    float: left;
    width: 49%;
    padding-left: 1.52rem;
    margin: 0;
    margin-bottom: 5px;
    box-sizing: border-box;
    position: relative;
    font-size: 0.293rem;
    line-height: 0.48rem;
    color: #666;
  }

  .custom-service-ul li img {
    width: 1.253rem;
    height: 1.253rem;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .custom-service-ul li span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }
  .account-info li {
    border-bottom: 0.027rem solid #eee;
    padding: 0.533rem 0;
  }

  .account-info p {
    font-size: 0.4rem;
    line-height: 0.587rem;
    color: #444444;
    margin: 0 auto;
    padding-left: 0.52rem;
  }

  .create-img {
    width: 100%;
    display: block;
  }

  .tip {
    width: 100%;
    height: 6.933rem;
    background-color: #df0024;
    text-align: center;
    font-size: 0.533rem;
    color: #fff;
    padding-top: 2.667rem;
    box-sizing: border-box;
    font-weight: bold;
  }

  .tip p {
    font-size: 0.373rem;
    color: #fff;
    margin-top: 0.4rem;
    font-weight: normal;
  }

  .tip-container p {
    font-size: 0.4rem;
    color: #444444;
    line-height: 0.587rem;
    margin-top: 0.267rem;
    text-indent: 2em;
  }

  .tip-container a {
    color: #df0024;
  }

  .development-history {
    border-top: 0.16rem solid #eee;

    padding: 0 0.267rem;
    padding-top: 0.667rem;
    padding-bottom: 0.5rem;
  }
  .dev-title {
    text-align: center;
    font-size: 0.46rem;
    font-weight: bold;
    color: #444444;
  }

  .dev-desc {
    font-size: 0.4rem;
    color: #444444;
    margin-top: 0.4rem;
    font-weight: bold;
    text-align: center;
    border-bottom: 1px solid #ccc;
    padding-bottom: 0.5rem;
  }

  .dev-list {
    font-weight: normal;
    font-size: 0.427rem;
    color: #444444;
    border-bottom: 0.027rem solid #eee;
  }

  .dev-list p {
    margin: 1em 0;
  }

  .video-wapper {
    width: 9.467rem;
    height: 5.333rem;
    margin: 0.4rem auto;
  }
.video_title{
  font-size: 0.36rem;
  line-height: 0.587rem;
  color: #444444;
  margin: 0 auto;
  padding-left: 0;
}
.swiper-button-prev:after{
  display: none;
}
.swiper-button-next:after{
  display: none;
}
.ly_home_product_left{
  width:30px;
  height: 30px;
  position: absolute;
  left:0;
  top:50%;
  background-image: url("../assets/img/about/left.png");
  background-repeat: no-repeat;
}
.introduction{
  width:100%;
  position: relative;
}
.ly_home_product_right{
  width:30px;
  height: 30px;
  position: absolute;
  right:0;
  top:50%;
  background-image: url("../assets/img/about/right.png");
  background-repeat: no-repeat;
}
.about_introduction_title{
  font-size: 16px;
  text-align: center;
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
}
.about_introduction_text{
  font-size: 15px;
  line-height: 0.8rem;
  padding-left: 0.2rem;
}
</style>
